import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class ConfigService {

	constructor(private http: HttpClient) { }

	public getBasketUrl() {
		return this.http.get('rest/configuration/basketurl', { responseType: 'text' });
	}

	public getPlanungsUrl() {
		return this.http.get('rest/configuration/planungsurl', { responseType: 'text' });
	}

	public getBilderweltUrl() {
		return this.http.get('rest/configuration/bilderwelturl', { responseType: 'text' });
	}
}
