import {NgModule} from '@angular/core';
import {FooterComponent} from './footer.component';
import {CommonModule} from '@angular/common';
import {MsgKeyModule} from '../../shared/translate/msg-key.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {FooterServerInfoComponent} from './footer-serverinfo/footer-serverinfo.component';
import {FooterCopyrightComponent} from './footer-copyright/footer-copyright.component';
import {FooterTermsOfUseComponent} from './footer-terms-of-use/footer-terms-of-use.component';
import {FooterImpressumComponent} from './footer-impressum/footer-impressum.component';
import {FooterContactComponent} from './footer-contact/footer-contact.component';
import {FooterHistoryComponent} from './footer-history/footer-history.component';
import {FooterPrivacyPolicyComponent} from './footer-privacy-policy/footer-privacy-policy.component';
import {FooterHelpComponent} from './footer-help/footer-help.component';
import {FeatureModule} from '../../permissions/feature/feature.module';
import {UserModule} from '../../permissions/user/user.module';
import {DirectivesModule} from '../../shared/directives/directives.module';
import {FooterFaqComponent} from "./footer-faq/footer-faq.component";

@NgModule({
	declarations: [
		FooterComponent,
		FooterServerInfoComponent,
		FooterCopyrightComponent,
		FooterFaqComponent,
		FooterTermsOfUseComponent,
		FooterImpressumComponent,
		FooterContactComponent,
		FooterHistoryComponent,
		FooterPrivacyPolicyComponent,
		FooterHelpComponent
	],
	imports: [
		CommonModule,
		MsgKeyModule,
		FeatureModule,
		UserModule,
		BrowserAnimationsModule,
		RouterModule,
		DirectivesModule
	],
	exports: [
		FooterComponent,
		FooterServerInfoComponent
	]
})
export class FooterModule {
	constructor() {
	}
}
